<template>
  <div id="account">
    <h1>Mon compte</h1>

    <div class="ui stackable grid">
      <div class="five wide column">
        <UserProfile />
        <UserActivity />
      </div>

      <div class="eleven wide column">
        <UserProjectsList />
      </div>
    </div>
  </div>
</template>

<script>
import UserProfile from '@/components/Account/UserProfile.vue';
import UserProjectsList from '@/components/Account/UserProjectsList.vue';
import UserActivity from '@/components/Account/UserActivity.vue';

export default {

  name: 'Account',

  components: {
    UserProfile,
    UserProjectsList,
    UserActivity
  },
};
</script>

<style lang="less" scoped>

#account {
  max-width: 1000px;
}

</style>