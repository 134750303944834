<template>
  <div>
    <h4 class="ui horizontal divider header">
      PROFIL
    </h4>

    <div class="ui divided list">
      <div class="item">
        <div class="right floated content">
          <div class="description">
            <span v-if="user.username">{{ user.username }} </span>
          </div>
        </div>
        <div class="content">
          Nom d'utilisateur
        </div>
      </div>

      <div class="item">
        <div class="right floated content">
          <div class="description">
            {{ userFullname }}
          </div>
        </div>
        <div class="content">
          Nom complet
        </div>
      </div>

      <div class="item">
        <div class="right floated content">
          <div class="description">
            {{ user.email }}
          </div>
        </div>
        <div class="content">
          Adresse e-mail
        </div>
      </div>

      <div class="item">
        <div class="right floated content">
          <div class="description">
            {{ user.is_superuser ? "Oui" : "Non" }}
          </div>
        </div>
        <div class="content">
          Administrateur
        </div>
      </div>
    </div>

    <div
      v-if="qrcode"
      class="qrcode"
    >
      <img :src="qrcode">
      <p>
        Ce QR code vous permet de vous connecter à l'application mobile GéoContrib (bientôt disponible)
      </p>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import QRCode from 'qrcode';

export default {

  name: 'UserProfile',

  data() {
    return {
      qrcode: null
    };
  },

  computed: {
    ...mapState([
      'configuration',
      'user',
      'userToken'
    ]),

    userFullname() {
      if (this.user.first_name || this.user.last_name) {
        return `${this.user.first_name} ${this.user.last_name}`;
      }
      return null;
    },
  },

  created() {
    this.GET_USER_TOKEN()
      .then(async () => {
        try {
          const qrcodeData = {
            url: `${this.configuration.VUE_APP_DJANGO_BASE}/geocontrib/`,
            token: this.userToken
          };
          this.qrcode = await QRCode.toDataURL(JSON.stringify(qrcodeData));
        } catch (err) {
          console.error(err);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  },

  methods: {
    ...mapActions([
      'GET_USER_TOKEN'
    ])
  }

};
</script>

<style scoped lang="less">
.qrcode {
  img {
    display: block;
    margin: auto;
    width: 12rem;
  }
  p {
    font-size: 0.8rem;
    font-style: italic;
    text-align: center;
  }
}
</style>
