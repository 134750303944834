<template>
  <div
    id="user-activity"
    class="ui stackable cards"
  >
    <!-- EVENTS -->
    <div class="red card">
      <div class="content">
        <div class="center aligned header">
          Mes dernières notifications reçues
        </div>
        <div class="center aligned description">
          <div class="ui relaxed list">
            <div
              v-for="item in events"
              :key="item.id"
              class="item"
            >
              <div :class="['content', { 'ellipsis nowrap': item.related_feature.title }]">
                {{ getNotificationName(item.event_type, item.object_type) }}

                <div 
                  v-if="item.object_type === 'project'"
                >
                  <router-link
                    v-if="item.project_title"
                    :to="{
                      name: 'project_detail',
                      params: { slug: item.project_slug },
                    }"
                  >
                    {{ item.project_title }}
                  </router-link>
                  <span
                    v-else
                    class="meta"
                  ><del>{{ item.project_slug }}</del>&nbsp;(supprimé)</span>
                </div>

                <div v-else>
                  <FeatureFetchOffsetRoute
                    v-if="item.related_feature.deletion_on === 'None'"
                    :feature-id="item.feature_id"
                    :properties="{
                      feature_type: {
                        slug: item.feature_type_slug
                      },
                      title: item.related_feature.title,
                      ...item
                    }"
                  />
                  <span
                    v-else
                    class="meta"
                  ><del>{{ item.data.feature_title || item.feature_id }}</del>&nbsp;(supprimé)</span>
                </div>

                <div class="description">
                  <em>[ {{ item.created_on }}
                    <span v-if="user">
                      , par {{ item.display_user }}
                    </span>
                    ]</em>
                </div>
              </div>
            </div>

            <em
              v-if="!events || events.length === 0"
            >Aucune notification pour le moment.</em>
          </div>
        </div>
      </div>
    </div>

    <!-- FEATURES -->
    <div class="orange card">
      <div class="content">
        <div class="center aligned header">
          Mes derniers signalements
        </div>
        <div class="center aligned description">
          <div class="ui relaxed list">
            <div
              v-for="item in features"
              :key="item.id"
              class="item"
            >
              <div class="content">
                <div>
                  <FeatureFetchOffsetRoute
                    v-if="item.related_feature.deletion_on === 'None'"
                    :feature-id="item.feature_id"
                    :properties="{
                      feature_type: {
                        slug: item.feature_type_slug
                      },
                      title: item.related_feature.title,
                      ...item
                    }"
                  />
                  <span
                    v-else
                    class="meta"
                  >
                    <del>{{ item.data.feature_title || item.feature_id }}</del>&nbsp;(supprimé)
                  </span>
                </div>

                <div class="description">
                  <em>[ {{ item.created_on }}
                    <span v-if="user">
                      , par {{ item.display_user }}
                    </span>
                    ]</em>
                </div>
              </div>
            </div>
            <em
              v-if="!features || features.length === 0"
            >Aucun signalement pour le moment.</em>
          </div>
        </div>
      </div>
    </div>

    <!-- COMMENTS -->
    <div class="yellow card">
      <div class="content">
        <div class="center aligned header">
          Mes derniers commentaires
        </div>
        <div class="center aligned description">
          <div class="ui relaxed list">
            <div
              v-for="item in comments"
              :key="item.id"
              class="item"
            >
              <div class="content">
                <div>
                  <FeatureFetchOffsetRoute
                    v-if="item.related_feature.deletion_on === 'None'"
                    :feature-id="item.feature_id"
                    :properties="{
                      feature_type: {
                        slug: item.feature_type_slug
                      },
                      title: quoteComment(item.data.comment),
                      ...item
                    }"
                  />
                  <span
                    v-else
                    class="meta"
                  >
                    <del>{{ item.data.comment }}</del>&nbsp;(supprimé)
                  </span>
                </div>

                <div class="description">
                  <em>[ {{ item.created_on }}
                    <span v-if="user">
                      , par {{ item.display_user }}
                    </span>
                    ]</em>
                </div>
              </div>
            </div>

            <em
              v-if="!comments || comments.length === 0"
            >Aucun commentaire pour le moment.</em>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import miscAPI from '@/services/misc-api';
import FeatureFetchOffsetRoute from '@/components/Feature/FeatureFetchOffsetRoute';

export default {
  name: 'UserActivity',

  components: {
    FeatureFetchOffsetRoute,
  },

  data() {
    return {
      events: [],
      features: [],
      comments: [],
    };
  },

  computed: {
    ...mapState([
      'user',
    ]),

    isSharedProject() {
      return this.$route.path.includes('projet-partage');
    },
  },

  created(){
    this.getEvents();
    // unset project to avoid interfering with generating query in feature links 
    this.$store.commit('projects/SET_PROJECT', null);
  },

  methods: {
    getEvents(){
      miscAPI.getUserEvents(this.$route.params.slug)
        .then((data)=>{
          this.events = data.events;
          this.features = data.features;
          this.comments = data.comments;
        });
    },

    getNotificationName(eventType, objectType) {
      if (eventType === 'create') {
        if (objectType === 'feature') {
          return 'Signalement créé';
        } else if (objectType === 'comment') {
          return 'Commentaire créé';
        } else if (objectType === 'attachment') {
          return 'Pièce jointe ajoutée';
        } else if (objectType === 'project') {
          return 'Projet créé';
        }
      } else if (eventType === 'update') {
        if (objectType === 'feature') {
          return 'Signalement mis à jour';
        } else if (objectType === 'project') {
          return 'Projet mis à jour';
        }
      } else if (eventType === 'delete') {
        if (objectType === 'feature') {
          return 'Signalement supprimé';
        } else if (objectType === 'project') {
          return 'Projet mis à jour';
        } else {
          return 'Événement inconnu';
        }
      }
    },

    quoteComment(comment) {
      return `"${comment}"`;
    },
  }

};
</script>

<style scoped lang="less">

#user-activity {
  flex-flow: column;
  margin: 1em 0;

  .card {
    margin: .875em 0;
  }
}

</style>
