var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui stackable cards",attrs:{"id":"user-activity"}},[_c('div',{staticClass:"red card"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"center aligned header"},[_vm._v(" Mes dernières notifications reçues ")]),_c('div',{staticClass:"center aligned description"},[_c('div',{staticClass:"ui relaxed list"},[_vm._l((_vm.events),function(item){return _c('div',{key:item.id,staticClass:"item"},[_c('div',{class:['content', { 'ellipsis nowrap': item.related_feature.title }]},[_vm._v(" "+_vm._s(_vm.getNotificationName(item.event_type, item.object_type))+" "),(item.object_type === 'project')?_c('div',[(item.project_title)?_c('router-link',{attrs:{"to":{
                    name: 'project_detail',
                    params: { slug: item.project_slug },
                  }}},[_vm._v(" "+_vm._s(item.project_title)+" ")]):_c('span',{staticClass:"meta"},[_c('del',[_vm._v(_vm._s(item.project_slug))]),_vm._v(" (supprimé)")])],1):_c('div',[(item.related_feature.deletion_on === 'None')?_c('FeatureFetchOffsetRoute',{attrs:{"feature-id":item.feature_id,"properties":{
                    feature_type: {
                      slug: item.feature_type_slug
                    },
                    title: item.related_feature.title,
                    ...item
                  }}}):_c('span',{staticClass:"meta"},[_c('del',[_vm._v(_vm._s(item.data.feature_title || item.feature_id))]),_vm._v(" (supprimé)")])],1),_c('div',{staticClass:"description"},[_c('em',[_vm._v("[ "+_vm._s(item.created_on)+" "),(_vm.user)?_c('span',[_vm._v(" , par "+_vm._s(item.display_user)+" ")]):_vm._e(),_vm._v(" ]")])])])])}),(!_vm.events || _vm.events.length === 0)?_c('em',[_vm._v("Aucune notification pour le moment.")]):_vm._e()],2)])])]),_c('div',{staticClass:"orange card"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"center aligned header"},[_vm._v(" Mes derniers signalements ")]),_c('div',{staticClass:"center aligned description"},[_c('div',{staticClass:"ui relaxed list"},[_vm._l((_vm.features),function(item){return _c('div',{key:item.id,staticClass:"item"},[_c('div',{staticClass:"content"},[_c('div',[(item.related_feature.deletion_on === 'None')?_c('FeatureFetchOffsetRoute',{attrs:{"feature-id":item.feature_id,"properties":{
                    feature_type: {
                      slug: item.feature_type_slug
                    },
                    title: item.related_feature.title,
                    ...item
                  }}}):_c('span',{staticClass:"meta"},[_c('del',[_vm._v(_vm._s(item.data.feature_title || item.feature_id))]),_vm._v(" (supprimé) ")])],1),_c('div',{staticClass:"description"},[_c('em',[_vm._v("[ "+_vm._s(item.created_on)+" "),(_vm.user)?_c('span',[_vm._v(" , par "+_vm._s(item.display_user)+" ")]):_vm._e(),_vm._v(" ]")])])])])}),(!_vm.features || _vm.features.length === 0)?_c('em',[_vm._v("Aucun signalement pour le moment.")]):_vm._e()],2)])])]),_c('div',{staticClass:"yellow card"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"center aligned header"},[_vm._v(" Mes derniers commentaires ")]),_c('div',{staticClass:"center aligned description"},[_c('div',{staticClass:"ui relaxed list"},[_vm._l((_vm.comments),function(item){return _c('div',{key:item.id,staticClass:"item"},[_c('div',{staticClass:"content"},[_c('div',[(item.related_feature.deletion_on === 'None')?_c('FeatureFetchOffsetRoute',{attrs:{"feature-id":item.feature_id,"properties":{
                    feature_type: {
                      slug: item.feature_type_slug
                    },
                    title: _vm.quoteComment(item.data.comment),
                    ...item
                  }}}):_c('span',{staticClass:"meta"},[_c('del',[_vm._v(_vm._s(item.data.comment))]),_vm._v(" (supprimé) ")])],1),_c('div',{staticClass:"description"},[_c('em',[_vm._v("[ "+_vm._s(item.created_on)+" "),(_vm.user)?_c('span',[_vm._v(" , par "+_vm._s(item.display_user)+" ")]):_vm._e(),_vm._v(" ]")])])])])}),(!_vm.comments || _vm.comments.length === 0)?_c('em',[_vm._v("Aucun commentaire pour le moment.")]):_vm._e()],2)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }